import React from "react";
import * as cssClass from "./privacy.module.scss";

const Privacy = () => {

  return (
    <div className={cssClass.privacy}>
      <div className={cssClass.inner}>
        <h1>PRIVACY AND DATA POLICY</h1>
        <p>ISSUED BY USTWO GAMES LTD | Last Updated: 23rd August 2022.</p>

        <h2>1. INTRODUCTION AND GENERAL TERMS</h2>
        <p>These terms apply to the use of games and other products and services referred to herein and developed and/or published by USTWO GAMES LTD (“ustwo” / “we” / “us”). Our registered office is at G.01 Tea Building, 56 Shoreditch High Street, London, United Kingdom, E1 6JJ.</p>
        <p>We are a company registered in England and Wales, with company number 09812157.</p>
        <p>This privacy and data policy (&quot; Privacy Policy&quot;) applies and has effect in respect of our game “Desta: The Memories Between” available on Netflix Games referred to herein as the “Game”.</p>
        <p>If you have any questions or comments about this Privacy Policy, please contact us at hello@ustwogames.co.uk.</p>
        <p>The Game collects information about your progression in the Game (“Game Progress”). This Game Progress information is not stored by us and is stored by Netflix services for game saves. We do not store personal or gameplay data on our servers.</p>
        <p>We are committed to protecting and respecting your privacy. This Privacy Policy explains the basis on which personal data we collect from you will be processed by us or on our behalf. Where we decide the purpose or means for which personal data you supply through the Game is processed, we are the “controller” for the purposes of the General Data Protection Regulation 2016/679 and the UK Data Protection Act 2018.</p>
        <p>You have the right to object to the processing of your personal data in certain circumstances. Information on your right to object, and your other rights, is set out below.</p>
        <p>Please read this Privacy Policy carefully as it contains important information about the following:</p>
        <ul>
          <li>What information we may collect about you;</li>
          <li>How we will use information we collect about you;</li>
          <li>Whether we will disclose your details to anyone else; and</li>
          <li>Your choices and rights regarding the personal data you have provided to us.</li >
        </ul>
        <p>This Privacy Policy should be read in conjunction with such Terms of Use as may be applicable in respect of the Game. Where Terms of Use are applicable, they will be made available via the Game.</p>
        <p>We do not accept any responsibility or liability for the privacy practices of third party services and your use of these is at your own risk.</p>
        <p>We may make changes to this Privacy Policy in the future, which will be made available here. You should check here from time to time to ensure you are aware of any changes. Where appropriate, we may notify you of changes by email or through the Game.</p >
        
        <h2>2. INFORMATION WE MAY COLLECT ABOUT YOU</h2>
        <p>In addition to the Game Progress information we may collect and process the following personal data from you (together, “Your Information”):</p>
        <p>Information that may be provided by you when you contact us for support</p>
        <ul>
          <li>Your name; and</li>
          <li>Your address, telephone number and email address.</li>
        </ul>

        <h2>3.  WHY WE COLLECT INFORMATION ABOUT YOU</h2>
        <h3>To respond to your enquiries and requests for support </h3>
        <p>We may process Your Information so that we are able to properly respond to your enquiries and support requests, in accordance with the Terms of Use as may be agreed between us.</p>
        <p>For the above purposes, we rely upon the legal basis of performance of a contract and our legitimate interests, being our commercial interests to respond to your queries and requests for support and to maintain good commercial practice in customer service.</p>
        <p>For these purposes please note that we work with vendor Zendesk, Inc. who assist us in managing customer service queries and may directly respond to our customers. You can find out more about Zendesk and their privacy practices on their website here: https://www.zendesk.co.uk/company/customers-partners/privacy-and-data-protection/.</p>
        <h3>To help us to fix any Game problems </h3>
        <p>We may process information about you (being Your Information or any part thereof) so that we can fix Game problems, bugs and defects (for example, if you report a bug to us within your support request).</p>
        <p>This processing is necessary for us to pursue our legitimate interests, being our commercial interests of: (i) ensuring that our Game functions properly so that you and other users have the best experience when, for example, playing the Game; (ii) improving the quality of our Game, and providing a better experience to our users; and (iii) identifying and correcting any bugs or problems with the Game.</p>

        <h2>4.  DATA SHARING</h2>
        <h3>We will share your information with third parties only in the ways that are described in this Privacy Policy</h3>
        <p><span className={cssClass.bold}>Group members, personnel, suppliers or subcontractors:</span> We keep your information confidential, but may disclose it to any member of our group (which means our subsidiaries, our ultimate holding company and its subsidiaries, as defined in section 1159 of the Companies Act 2006), our personnel, suppliers or subcontractors insofar as it is reasonably necessary for the purposes set out in this Privacy Policy. However, this is on the basis that they do not make independent use of the information, and have agreed to safeguard it.</p>
        <p><span className={cssClass.bold}>Merger or acquisition:</span> If we are involved in a merger, acquisition, transfer or sale of all or a portion of our assets, you will be notified (for example, via email, account message and/or a prominent notice on our website) of any change in ownership or uses of this information, as well as any choices you may have regarding this information.</p>
        <p><span className={cssClass.bold}>Required by law:</span> We may disclose your information to the extent that we are required to do so by law (which may include to government bodies and law enforcement agencies); in connection with any legal proceedings or prospective legal proceedings; and in order to establish, exercise or defend our legal rights (including providing information to others for the purposes of fraud prevention).</p>
        <p><span className={cssClass.bold}>Enforcement:</span> We may also disclose your personal data to third parties (e.g. mobile network operators, gaming platform operators, game console manufacturers or networks) in order to enforce or apply the terms of agreements, to investigate potential breaches or to protect our rights, property or safety, or those of our customers or others.</p>

        <h2>5.  YOUR RIGHTS IN RELATION TO PERSONAL DATA WHICH WE PROCESS RELATING TO YOU</h2>
        <p>You have the following rights over the way we process personal data relating to you, as set out in the table below. We aim to comply with requests without undue delay and within one month at the latest.</p>
        <p>To make a request, please let us know by email to hello@ustwogames.co.uk.</p>
        <h3>Ask for a copy of data we are processing about you and have inaccuracies corrected</h3>
        <p>You have the right to request a copy of the personal data we hold about you and to have any inaccuracies corrected.</p>
        <p>We will use reasonable efforts, to the extent required by law, to supply, correct or delete personal data held about you on our files (and with any third parties to whom it has been disclosed to).</p>
        <h3>Objecting to us processing data about you</h3>
        <p>You can ask us to restrict, stop processing or delete your personal data if:</p>
        <ul>
          <li>you consented to our processing the personal data and have withdrawn that consent;</li>
          <li>we no longer need to process that personal data for the reason it was collected;</li>
          <li>we are processing that personal data because it is in the public interest or it is in order to pursue a legitimate interest, you don’t agree with that processing and there is no overriding legitimate interest for us to continue processing it;</li>
          <li>the personal data was unlawfully processed;</li>
          <li>you need the personal data to be deleted in order to comply with legal obligations; and/or</li>
          <li>the personal data is processed in relation to the offer of a service to a child.</li>
        </ul>
        <h3>Obtain a machine-readable copy of your personal data, which you can use with another service provider</h3>
        <ul>
          <li>If (i) we are processing data in order to perform our obligations to you or because you consented and (ii) that processing is carried out by automated means, we will help you to move, copy or transfer your personal data to other IT systems.</li>
          <li>If you request, we will supply you with the relevant personal data in CSV format.  Where it is technically feasible, you can ask us to send this information directly to another IT system provider if you prefer.</li>
        </ul>
        <h3>Make a complaint to a Supervisory Authority</h3>
        <ul>
          <li>If you are unhappy with the way we are processing your personal data, please let us know by contacting us via the support services.</li>
          <li>If you do not agree with the way we have processed your data or responded to your concerns, an alternative is to submit a complaint to a Data Protection Supervisory Authority. Information and contact details for the Information Commissioner’s Office, the UK’s Supervisory Authority, is available here: https://ico.org.uk/.</li>
        </ul>

        <h2>6.  DATA RETENTION</h2>
        <p>In accordance with data protection laws and good commercial practice, we do not retain data in a form that permits identification of the person to whom it relates for longer than 180 days following the relevant matter having been addressed and/or following inactivity (where applicable).</p>
        <h2>7.  CHILDREN</h2>
        <p>The Game collects, uses, and discloses data from children in the same way as for other users, as described in this policy. The Game’s use of this data is limited to support internal operations of the Game. If you wish for this information to be deleted please contact us.</p>
        <h2>8.  SECURITY</h2>
        <p>Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access. Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted through our Game; any transmission is at your own risk</p>
        <h2>9.  INTERNATIONAL DATA TRANSFERS</h2>
        <p>The data we collect from you may be transferred to, and stored at, a destination outside the European Economic Area (“EEA”). It may also be processed by staff that operate outside the EEA and work for us or our suppliers. These staff may be engaged in the fulfilment of your orders, the processing of your payment details, the maintenance of the Service, and the provision of support services. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this privacy policy.</p>
        <p>Where your personal data is transferred outside of the EEA to a territory not subject to an adequacy decision by the European Commission, we have agreements in place with the relevant parties which include either (i) standard data protection clauses adopted by the relevant data protection regulator and approved by the European Commission or (ii) standard data protection clauses adopted by the European Commission, to ensure that appropriate safeguards are used to protect your personal data. Alternatively, we may put in place other appropriate safeguards. If you require more information about these safeguards, you can contact us at hello@ustwogames.co.uk.</p>
        <h2>10.  CONTACT INFORMATION</h2>
        <p>All questions, comments or enquiries should be directed to hello@ustwogames.co.uk.  We will endeavor to respond to any query or questions within 5 business days.</p>
        <p>© 2022 USTWO GAMES LTD. All trade marks are the property of the relevant owners. All rights reserved.</p>

      </div>
    </div>
  );
}

export default Privacy;